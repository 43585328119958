const routes = {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',

  // Admin
  dashboard: '/',

  affiliates: {
    index: '/affiliates',
    detail: '/affiliates/:id',
  },

  towns: {
    index: '/towns',
    detail: '/towns/:id',
  },

  checkIns: '/check-ins',

  competitions: {
    index: '/competitions',
    detail: '/competitions/:id',
  },

  events: {
    index: '/events',
    detail: '/events/:id',
  },

  tiers: {
    index: '/tiers',
    detail: '/tiers/:id',
  },

  addOns: {
    index: '/add-ons',
    detail: '/add-ons/:id',
  },

  users: {
    index: '/users',
    detail: '/users/:id',
  },

  administrators: {
    index: '/administrators',
    detail: '/administrators/:id',
  },

  management: '/management/:tab?',

  archive: '/archive/:tab?'
};

export default routes;
